export const MEDIA_TYPE = {
  MP4_VIDEO: 'video/mp4',
  OGG_VIDEO: 'video/ogg',
  WEBM_VIDEO: 'video/webm',
};

export const LANGUAGE = {
  en: 'US (English)',
  ca: 'Canada (French)',
};
